import { IQMContent, IQMLayout } from "@iqm-ui-library/booster";
import { useEffect, useState, lazy, useCallback } from "react";
import { Breadcrumb, Button, Col, Row, Tag } from "antd";
import { Typography } from 'antd';
import { AllReconciliations } from "./components/allReconciliations/allReconciliations";
import './home.scss'
import { ErrorContextProvider } from "./components/utils/errorContext";
import { CustomAlert } from "./components/alert/customAlert";
import { SuccessContextProvider } from "./components/utils/successContext";
import { ITemplate } from "./constants/template.interface";
import { IRuleSet } from "./constants/rule.interface";
import { LoaderContextProvider } from "./components/utils/loaderContext";
import { CustomLoader } from "./components/loader/customLoader";
import { CustomHeader } from "./components/header/customHeader";
import * as FullStory from '@fullstory/browser';
import { useCompany, useSession } from "./auth";
import { IReconciliationStatus, IReconciliationStatusColors } from "./components/reconciliationDetails/reconciliationDetailsPage.interface";

const ReconciliationForm = lazy(() => import("./components/simpleFormPage/reconciliationForm").then(module => ({ default: module.ReconciliationForm })));
const AllTemplates = lazy(() => import('./components/template/allTemplates').then(module => ({ default: module.AllTemplates })));
const TemplateForm = lazy(() => import('./components/template/templateForm').then(module => ({ default: module.TemplateForm })));
const AllRules = lazy(() => import('./components/reconciliationRules/allRules').then(module => ({ default: module.AllRules })));
const RulesetForm = lazy(() => import('./components/reconciliationRules/ruleSetForm').then(module => ({ default: module.RulesetForm })));
const ReconciliationDetailsPage = lazy(() => import("./components/reconciliationDetails/reconciliationDetailsPage").then(module => ({ default: module.ReconciliationDetailsPage })));
const ReconciliationActionButtons = lazy(() => import("./components/reconciliationDetails/reconciliationDetailsPage").then(module => ({ default: module.ReconciliationActionButtons })));

const { Title } = Typography;
export function ReconciliationHome() {
    const [selectedPage, setSelectedPage] = useState('AR')
    const [navigatedFrom, setNavigatedFrom] = useState('')
    const [formData, setFormData] = useState<ITemplate | IRuleSet | any>()
    const [selectedItem, setSelectedItem] = useState<any>()
    const [reconciliationId, setReconciliationId] = useState<number>()

    /*Onclick on seeDetails trigger handleMenu to render details page*/
    // Memoize the seeDetails function to prevent unnecessary re-renders
    const seeDetails = useCallback((e: any, selectedItem?: any) => {
        setSelectedItem(selectedItem);
        setSelectedPage(e);
    }, []);

    /** Set selected page and update breadcrumb list */
    const handleMenu: any = (e?: any, isReset: boolean = true) => {
        if(isReset) {
            resetState();
        }
        setSelectedPage(e.key)
        breadcrumbList.length = 1;
        breadcrumbList.push({ title: keyValueObj[e.key].label })
        setBreadcrumbUpdatedList(breadcrumbList);
    }

    const onTemplateEdit = (val: ITemplate, navigatedFrom?: string) => {
        setFormData(val);
        navigatedFrom && setNavigatedFrom(navigatedFrom);
        handleMenu({ key: 'UT' }, false);
    }
    
    const onRuleSetEdit = (val: IRuleSet, navigatedFrom?: string) => {
        setFormData(val);
        navigatedFrom && setNavigatedFrom(navigatedFrom);
        handleMenu({ key: navigatedFrom == 'TM' ? 'CR' :'RT' }, false);
    }

    const reconciliationSubmit = (id: number) => {
        setReconciliationId(id);
    }

    const resetState = () => {
        setNavigatedFrom('');
        setFormData(null);
    }

    /** Set sidebar key value pair items */
    const keyValueObj: any = {
        AR: {
            headerLabel: 'Reconciliations',
            label: 'All reconciliations',
            component: <AllReconciliations parentHandler={seeDetails} />,
            buttonLabel: "Start reconciliation",
            onClick: () => handleMenu({ key: 'NC' })
        },
        NC: {
            headerLabel: null,
            label: 'New reconciliation',
            component: <ReconciliationForm handleMenu={handleMenu} onFormSubmit={reconciliationSubmit}></ReconciliationForm>
        },
        CT: {
            headerLabel: null,
            label: 'New template',
            hidden: true,
            component: <TemplateForm handleMenu={handleMenu}></TemplateForm>
        },
        UT: {
            headerLabel: null,
            label: 'Edit template',
            hidden: true,
            component: <TemplateForm handleMenu={handleMenu} formData={formData} navigatedFrom={navigatedFrom}></TemplateForm>
        },
        RT: {
            headerLabel: null,
            label: 'Edit ruleset',
            hidden: true,
            component: <RulesetForm handleMenu={handleMenu} formData={formData} navigatedFrom={navigatedFrom}></RulesetForm>
        },
        TM: {
            headerLabel: "Template management",
            label: "Template management",
            component: <AllTemplates onTemplateEdit={onTemplateEdit} onRuleSetEdit={onRuleSetEdit} />,
            buttonLabel: "Create template",
            onClick: () => handleMenu({ key: 'CT' })
        },
        CR: {
            headerLabel: null,
            label: 'New ruleset',
            hidden: true,
            component: <RulesetForm handleMenu={handleMenu} formData={formData} navigatedFrom={navigatedFrom}></RulesetForm>
        },
        RM: {
            headerLabel: 'Ruleset management',
            label: 'Ruleset management',
            component: <AllRules onRuleSetEdit={onRuleSetEdit} handleMenu={handleMenu} />,
            buttonLabel: "Create ruleset",
            onClick: () => handleMenu({ key: 'CR' })
        },
        RD: {
            headerLabel: selectedItem?.name,
            label: <><span style={{marginRight: '10px'}}>{selectedItem?.name + ' (' + selectedItem?.reconciliationPeriod + ') '}</span> {<Tag color={IReconciliationStatusColors[selectedItem?.status  as keyof typeof IReconciliationStatusColors]}>{selectedItem?.status === IReconciliationStatus.ReadyToReview ? 'Ready to review' : selectedItem?.status}</Tag>}</> ,
            component: <ReconciliationDetailsPage key={'key'} selectedItem={selectedItem} setSelectedItem={setSelectedItem}></ReconciliationDetailsPage>,
            buttonComponent: <ReconciliationActionButtons reconciliationId={selectedItem?.id} reconciliationStatus = {selectedItem?.status} handleMenu={handleMenu} seeDetails={seeDetails} selectedItem={selectedItem}/>,
            hidden: true,
            notInMenuList: true
        }
    };

    /*on clicking on see details re-render the handleMenu to update page details*/
    useEffect(() => {
        if (selectedItem) {
            handleMenu({ key: selectedPage }, selectedItem)
        }
    }, [selectedItem, selectedPage]);

    /* Set breadcrumb list when clicked on menu item  */
    const breadcrumbList: any = [
        {
            title: <a onClick={() => {handleMenu({key: 'AR'})}}>Home</a>,
        }
    ]
    const [breadcrumbUpdatedList, setBreadcrumbUpdatedList] = useState([...breadcrumbList])

    const session: any = useSession();
    const company: any = useCompany();

    useEffect(() => {
        if (process.env.REACT_APP_FULLSTORY_ENABLED === 'true') {
            initFullStory('o-1A2CZG-na1', company?.parent?.company?.id, session?.session?.user?.id);
        }
    }, []);

    function initFullStory(orgId: string, parentEntityId: string, userId: string) {
        FullStory.init({ orgId: orgId },
            ({ sessionUrl }) => console.log(`Started session: ${sessionUrl}`));
        FullStory.identify(parentEntityId + ':' + userId, {});
    }

    return (
        <LoaderContextProvider>
            <CustomLoader />
            <ErrorContextProvider>
                <SuccessContextProvider>
                    <IQMLayout key={"IQM-HOME-LAYOUT"} className={'home'}>
                        <CustomHeader seeDetails={seeDetails} handleMenu={handleMenu} keyValueObj={keyValueObj} reconciliationId={reconciliationId} selectedKey={selectedPage}/>

                        <IQMLayout style={{ marginTop: 64 }}>
                            <IQMContent pageData={
                                <>
                                    <div style={{ padding: '16px 48px', borderBottom: '1px solid rgb(0, 0, 0, 0.15)' }}>
                                        <Row gutter={[16, 24]} align={"middle"}>
                                            <Col span={14}><Breadcrumb
                                                items={breadcrumbUpdatedList} />
                                            </Col>
                                            <Col span={10}>
                                                {keyValueObj[selectedPage]?.buttonLabel ?
                                                    <Row justify={"end"}><Button type={"primary"} key={'NC'} onClick={() => {
                                                        keyValueObj[selectedPage]?.onClick()
                                                        }}>{keyValueObj[selectedPage]?.buttonLabel}</Button>
                                                    </Row>
                                                    : keyValueObj[selectedPage]?.buttonComponent?
                                                        <Row justify={"end"}>
                                                            <Col>
                                                                {keyValueObj[selectedPage]?.buttonComponent}
                                                            </Col>
                                                        </Row>
                                                        :null
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                    {selectedPage === 'RD' ? <div style={{padding: '0 48px 16px'}}
                                                                  className={'customBackground'}>
                                            {selectedPage ? keyValueObj[selectedPage]?.component : <></>}
                                        </div>
                                        : <div style={{padding: '16px 48px'}}>
                                                <Title style={{marginBottom: '20px'}}
                                                       level={2}>{keyValueObj[selectedPage]?.headerLabel}</Title>
                                            {selectedPage ? keyValueObj[selectedPage]?.component : <></>}
                                        </div>}
                                </>
                            }>
                            </IQMContent>
                            <CustomAlert/>
                        </IQMLayout>
                    </IQMLayout>
                </SuccessContextProvider>
            </ErrorContextProvider>
        </LoaderContextProvider>
    );
}
