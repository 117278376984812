import {Pagination, Row} from "antd";
import React, {useEffect, useState} from "react";
import {PaginationPosition} from "antd/es/pagination/Pagination";


export interface PaginationInterface{
    current:number;
    total:number
    pageSize:number
    onChange: (pagination: any, filters?: any, sort?: any) => void
    position?:PaginationPosition
    showQuickJumper?: boolean
    pageSizeOptions: string[] | number[];
    showSizeChanger?:boolean;
    disableLastPage?: boolean
}
export function PaginationComponent({current ,total,  pageSize, onChange, showQuickJumper, showSizeChanger, pageSizeOptions, disableLastPage} : PaginationInterface){
    const custShowQuickJumper = showQuickJumper === false ? false : true;
    const custShowSizeChanger = showSizeChanger === false ? false : true;

    const totalPages = Math.ceil(total / pageSize);
    const itemRender = (page: number, type: any, originalElement: React.ReactNode) => {
        if (type === 'page' && page === totalPages && disableLastPage) {
          return <span className={(page - currentPage) > 4 ? "disablePagination": ''}>{page}</span>;
        }
        return originalElement;
      };

    const [currentPage, setCurrent] = useState(current);
    
    function onChangePage(page:any, newPageSize:any) {
        if(page === totalPages && disableLastPage && (page - currentPage) > 4 ) return;
        setCurrent(pageSize !== newPageSize ? 1 : page);
        onChange({page, newPageSize , total:total});
    }

    useEffect(() => {
        setCurrent(current);
    }, [current]);

    return(
        <Row justify={"end"}>
            <Pagination current={currentPage} total={total} pageSize={pageSize} itemRender={itemRender} onChange={onChangePage} showQuickJumper={custShowQuickJumper} showSizeChanger={custShowSizeChanger}  pageSizeOptions={pageSizeOptions ? pageSizeOptions :[5,10]} />
        </Row>
    )
}

