export interface ReconciliationsPagedResponse  {
    totalCount?: string,
    listOfReconciliation: Records[]
}

export interface Records {
    "id": string,
    "name": string,
    "createdDateUtc": string,
    "periodStartDateUtc": string,
    "periodEndDateUtc": string,
    "status": string,
    "totalItemCount":string,
    "unmatchedItemCount": string,
    "reconciledItemCount": string,
    "lastUpdatedById": string,
    "lastUpdatedByName": string,
    "lastUpdatedDateUtc": string
    "onClick":   (item: Records) => void
    "action": string
    "reconciliationPeriod": string
    "unmatchedItemCountUI":string
    "reconciledItemCountUI":string
}

export enum IAllReconciliationStatus {
    Processing = 'Processing',
    'Ready to review' = 'Ready',
    Committed = 'Committed',
    Completed = 'Completed',
    Discarded = 'Discarded',
    Failed = 'Failed'
}