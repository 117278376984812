export interface Reconciliation {
    id: number;
    name: string;
    totalItemCount: number;
    matched: Matched;
    reconciled: Reconciled;
    reasonCodes: ReasonCodes[];
    periodStartDateUtc: string;
    periodEndDateUtc: string;
    count: number;
    status: string;
}

interface ReasonCodes {
    count: number;
    reasonCode: string;
}

export interface Matched {
    matchedRecordCount: number;
    totalRecordCount: number;
}

interface Reconciled {
    reconciledRecordCount: number;
    totalRecordCount: number;
}

export interface ReconciliationDetailsProps {
    selectedItem: Reconciliation;
    setSelectedItem?: Function
}

export enum IReconciliationStatusColors {
    Processing = 'orange',
    Failed = 'magenta',
    'Ready to review' = 'green',
    'Ready' = 'green',
    Committed = 'blue',
    Discarded = 'magenta',
    Completed = 'blue'
}

export enum IReconciliationStatus {
    Processing = 'Processing',
    ReadyToReview = 'Ready',
    Failed = 'Failed',
    Committed = 'Committed',
    Discarded = 'Discarded',
    Completed = 'Completed',
    Pending = 'Pending',
    Cancel = 'Cancel',
    Commit = 'Commit',
    Discard = 'Discard',
    AllowEdit = 'Allow edit',
    Complete = 'Complete',
}

export enum IReconciliationModalMessages {
    Discard = 'Discarding the Reconciliation will remove all the edits to the data that are currently saved as draft.',
    Commit = 'All of the changes will be saved to your main data record.',
    Complete = 'Do you want to complete this reconciliation?',
    'Allow edit' = 'Do you want to allow to edit this reconciliation?',
}

export enum IReconciliationAlertMessages {
    ReadyToReview = 'Changes will be auto-saved as a draft',
    Commit = 'Changes will be auto-saved',
}

export interface IReconciliationActionButtons {
    reconciliationId: number;
    reconciliationStatus: string;
    handleMenu: Function
    seeDetails: Function
    selectedItem: Reconciliation
}

export enum IReconciliationModalActionPlaceholder {
    Currency = 'Insert the correct amount',
    PhoneNumber = "Insert the correct phone number",
    comments = 'Type here the comment for the records',
    reasonCode = 'Insert the new reason code',
}

export enum IReconciliationFinalStatus {
    Commit = 'Committed',
    Discard = 'Discarded',
    Complete = 'Completed',
    'Allow edit' = 'Committed'
}