import {TableColumn} from "@iqm-ui-library/booster";
import {dateFormat} from "../utils/commonFunctions";
import { Button, Input, InputRef, Space, Tag } from "antd";
import { IReconciliationStatus, IReconciliationStatusColors } from "../reconciliationDetails/reconciliationDetailsPage.interface";
import { FilterFilled, InfoOutlined } from "@ant-design/icons";
import { IAllReconciliationStatus } from "./allReconciliation.interface";
import { ColumnType } from "antd/es/table";
import { useRef } from "react";

const filterOptions = Object.entries(IAllReconciliationStatus).map(val => {
  return {
      text: val[0],
      value: val[1]
  }
});

interface DataType {
  key: string;
  name: string;
  status: string;
}

type DataIndex = keyof DataType;

export const GetColumns = (onFilter: (value: any, record: any) => boolean, resetData: () => void, onSearch:(selectedKeys: React.Key[]) => void, handleReset:(clearFilters: () => void) => void): any => {
  const searchInput = useRef<InputRef>(null);

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
        if(dataIndex == 'name'){
            return<div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
            <Input
                ref={searchInput}
                placeholder={`Filter ${dataIndex}`}
                value={`${selectedKeys[0] || ''}`}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => {
                    resetData();
                    onSearch(selectedKeys);
                    close()
                    confirm()
                }}
                style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() =>  {
                        resetData();
                        onSearch(selectedKeys);
                        close()
                        confirm()
                    }}
                    icon={ <FilterFilled />}
                    size="small"
                    style={{ width: 90 }}
                >Filter
                </Button>
                <Button
                    onClick={() => {
                        resetData();
                        clearFilters && handleReset(clearFilters);
                        close()
                        confirm()
                    }}
                    size="small"
                    style={{ width: 90 }}
                >Reset
                </Button>
            </Space>
        </div>
        }
      },
    filterIcon: (filtered: boolean) => (
        <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilterDropdownOpenChange: visible => {
        if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
        }
    }
  });

  return [
    {
      title: "Name",
      dataIndex: "name",
      order: 1,
      type: "text",
      ...getColumnSearchProps('name')
    },
    {
      title: "Created date",
      dataIndex: "createdDateUtc",
      order: 2,
      type: "text",
      render: (text: any) => dateFormat(text)
    },
    {
      title: "Reconciliation period",
      dataIndex: "reconciliationPeriod",
      order: 3,
      type: "text",
    },
    {
      title: "Status",
      dataIndex: "customStatus",
      order: 4,
      type: "tag",
      filters: filterOptions,
      onFilter,
      render: (text: any, record: any) => {
        return <>
          <Tag color={IReconciliationStatusColors[text as keyof typeof IReconciliationStatusColors]}>{text}</Tag>
        </>
      }
    },
    {
      title: "Unmatched items",
      dataIndex: "unmatchedItemCountUI",
      order: 5,
      type: "text",
    },
    {
      title: "Reconciled items",
      dataIndex: "reconciledItemCountUI",
      order: 6,
      type: "text",
    },
    {
      title: "Last edited by",
      dataIndex: "lastUpdatedByName",
      order: 7,
      type: "text",
    },
    {
      title: "Last update",
      dataIndex: "lastUpdatedDateUtc",
      order: 8,
      type: "text",
      render: (text: any) => dateFormat(text)
    },
    {
      title: "",
      dataIndex: "action",
      order: 9,
      type: "action",
    },
  ];
}

